/* AutoFadeAndGrowText */

.auto-grow-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 16vw;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.auto-grow-container.visible {
  opacity: 1;
}

.auto-grow-text {
  font-size: 0.2em;
  transition: font-size 1s ease-out;
  font-family: "timeburnerbold", sans-serif;
}

.auto-grow-text.grow {
  font-size: 1em;
}

/* ContactButton */

.ContactButtonStyle {
  background-color: transparent;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
}

.ContactButtonStyle:hover {
  background-color: white;
  color: black;
}

.ContactButtonArrow {
  margin-right: 10px;
}

/* Header */

#Header nav ul li {
  padding: 0 10px;
}

#Header nav ul li .header_link:hover {
  color: #c22212;
  font-size: large;
}

#Header .header_contact {
  padding: 5px 25px;
  border-radius: 10px;
  border: 4px solid #fff;
  text-decoration: none;
  letter-spacing: 0.15rem;
  box-shadow: 0 0 10px #fff, inset 0 0 10px #fff, 0 0 0px #fff, 0 0 0px #3e97e3, 0 0 0px #3e97e3, 0 0 10px #3e97e3, 0 0 10px #3e97e3, inset 0 0 20px #3e97e3;
  text-shadow: 0 0 10px #fff, 0 0 10px #fff, 0 0 0px #fff, 0 0 0px #c22212, 0 0 0px #c22212, 0 0 10px #c22212, 0 0 10px #c22212, 0 0 20px #c22212;
}

#Header .header_link {
  font-size: large;
  color: #fff;
}

#Header .header_link_disabled {
  font-size: large;
  color: #fff;
  pointer-events: none;
}

#Header .header_contact .header_link .header_link_disabled {
  font-size: large;
  color: #fff;
}

.home-header {
  width: 100%;
  background-color: rgb(1 1 1 / 1);
  padding: 10px 4% 0px;
  position: fixed;
  top: 0;
  opacity: 1;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 99;
}

.fixed-header {
  width: 100%;
  background-color: rgb(1 1 1 / 1);
  padding: 10px 4% 0px;
  top: 0;
  opacity: 1;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 99;
}

.menu-toggle {
  display: none;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  #Header nav ul {
    border-bottom: 2px solid white;
    padding-bottom: 10px;
    align-items: center;
  }

  #Header .logo {
    width: 20%;
  }
}

@media screen and (min-width: 1025px) {
  #Header nav ul {
    border-bottom: 2px solid white;
    padding-bottom: 10px;
    align-items: center;
  }

  #Header .logo {
    width: 20%;
  }
}

@media screen and (max-width: 768px) {

  #Header nav ul {
    flex-direction: column;
    display: none;
    align-items: center;
  }

  #Header nav ul li {
    padding: 10px 0;
  }

  #Header nav.open ul {
    display: flex;
    z-index: 50;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    display: block;
    width: 100%;
    background: rgba(0, 0, 0);
    transition: all .2s ease-in-out;
  }

  #Header nav.open ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  #Header nav.open li {
    margin: 0;
    padding: 0;
  }

  #Header nav.open li a {
    display: block;
    padding: 20px 0;
  }

  .menu-toggle {
    display: block;
    background: none;
    border: none;
    font-size: 2rem;
    color: white;
    cursor: pointer;
    z-index: 51;
  }

  .close.menu-toggle {
    display: block;
    background: none;
    border: none;
    font-size: 2rem;
    color: white;
    cursor: pointer;
  }

  #Header .logo {
    width: 20%;
  }
}

/* Footer */

.footer-container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.contact-rich-footer {
  background-color: #333;
  color: #fff;
  padding: 50px 100px;
  width: 100%;
}

.bottom-container {
  padding-top: 40px;
  text-align: center;
}

.bottom-container {
  padding-top: 10px;
  border-top: 1px solid #ffffff;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.home-rich-footer {
  background-color: #333;
  color: #fff;
  padding-top: 50px;
  padding-bottom: 10px;
  width: 100%;
  position: relative;
  z-index: 49;
}

#Footer nav ul li {
  padding: 0 10px;
}

#Footer nav ul li .footer_link {
  color: #ffffff;
  font-size: large;
}

#Footer nav ul li .footer_link:hover {
  color: #c22212;
  font-size: large;
}

#Footer .footer_link_disabled {
  font-size: large;
  color: #fff;
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  .center-top-container {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .center-bottom-container {
    padding-top: 50px;
    margin-left: 10%;
    margin-right: 10%;
    font-size: small;
    padding-bottom: 10px;
  }

  .top-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-left,
  .footer-right {
    width: 100%;
    text-align: center;
  }

  .footer-right nav ul {
    flex-direction: column;
    gap: 10px;
  }

  .footer-left img {
    width: 60%;
  }

  .instagramIcon {
    background: linear-gradient(45deg, rgba(254, 212, 117, 1) 0%, rgba(229, 61, 93, 1) 50%, rgba(194, 49, 134, 1) 70%, rgba(156, 56, 187, 1) 100%);
    width: 40px;
    margin-top: 20px;
    margin-bottom: -30px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .center-top-container {
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center; 
  }

  .center-bottom-container {
    padding-top: 50px;
    margin-left: 10%;
    font-size: small;
    padding-bottom: 10px;
  }

  .footer-left img {
    width: 80%;
  }


  .instagramIcon {
    background: linear-gradient(45deg, rgba(254, 212, 117, 1) 0%, rgba(229, 61, 93, 1) 50%, rgba(194, 49, 134, 1) 70%, rgba(156, 56, 187, 1) 100%);
    width: 40px;
    margin-top: 20px;
    margin-bottom: -20px;
  }
}

@media screen and (min-width: 1025px) {
  .center-top-container {
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center; 
  }

  .center-bottom-container {
    padding-top: 50px;
    margin-left: 10%;
    font-size: small;
    padding-bottom: 10px;
  }

  .footer-left img {
    width: 40%;
  }

  .instagramIcon {
    background: linear-gradient(45deg, rgba(254, 212, 117, 1) 0%, rgba(229, 61, 93, 1) 50%, rgba(194, 49, 134, 1) 70%, rgba(156, 56, 187, 1) 100%);
    width: 40px;
    margin-top: 20px;
    margin-bottom: -20px;
  }
}

/* MoreButton */

.MoreButtonContainerStyle {
  padding-top: 40px;
}

.MoreButtonStyle {
  background-color: transparent;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
}

.MoreButtonStyle:hover {
  background-color: white;
  color: black;
}

.MoreButtonArrow {
  margin-right: 10px;
}

/* AreaCard */

.CardImage {
  background-color: #007bff;
  opacity: 0.7;
  aspect-ratio: 16 / 10;
  background-position: center;
  background-size: cover;
  max-width: 100%;
  border: 1px solid #333;
}

.CardContent {
  padding-top: 10px;
  flex-grow: 1;
}

@media screen and (max-width: 768px) {
  .CardContainer {
    border: solid 1px;
    padding: 30px;
    max-width: 100%;
  }

  .CardTitle {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
    font-size: 1.5em;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .CardContainer {
    border: solid 1px;
    padding: 15px;
    max-width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  .CardTitle {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
    font-size: 1.25em;
    line-height: 1.5em;
  }
}

@media screen and (min-width: 1025px) {
  .CardContainer {
    border: solid 1px;
    padding: 15px;
    max-width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  .CardTitle {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
    font-size: 1.4em;
    line-height: 2em;
  }
}

/* ReactPaginate */

.PaginationContainerStyle {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.PaginationPageStyle {
  font-size: medium;
}

.PaginationPageStyle:hover {
  color: #c22212;
}

/* ParticleStyle */
.ParticlesStyle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

/* ServicesCardNormal */

.ServicesCardNormalLeftRightContainer {
  display: flex;
  margin-right: 5%;
  margin-left: 5%;
  align-items: center;
  justify-content: space-between;
}

.ServicesCardNormalTitleENBarStyle {
  width: 10px;
  height: 20px;
  background-color: #1d3ed9;
  margin-right: 20px;
}

.ServicesCardNormalTitleENStyle {
  margin: 0;
  color: #ffffff;
  font-weight: 300;
  letter-spacing: 0.05em;
}

.ArrowCircleDownIconStyle {
  color: #333;
}

.ArrowCircleDownIconStyle:hover {
  color: blue;
}

@media screen and (max-width: 768px) {
  .ServicesCardNormalContainer {
    box-shadow: 1px 1px 5px 1px #999;
    width: 80%;
    margin: 0 auto;
    padding: 10px;
  }

  .ServicesCardNormalTitleContainer {
    align-items: center;
    display: flex;
    font-size: small;
  }

  .ServicesCardNormalContentsContainer {
    margin: 0 10px 0 30px;
    text-align: justify;
    font-size: small;
    padding-top: 5px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .ServicesCardNormalContainer {
    box-shadow: 1px 1px 5px 1px #999;
    width: 50%;
    margin: 0 auto;
    padding: 10px;
  }

  .ServicesCardNormalTitleContainer {
    align-items: center;
    display: flex;
  }

  .ServicesCardNormalContentsContainer {
    margin: 0 10px 0 30px;
    text-align: justify;
    padding-top: 5px;
  }
}

@media screen and (min-width: 1025px) {
  .ServicesCardNormalContainer {
    box-shadow: 1px 1px 5px 1px #999;
    width: 50%;
    margin: 0 auto;
    padding: 10px;
  }

  .ServicesCardNormalTitleContainer {
    align-items: center;
    display: flex;
  }

  .ServicesCardNormalContentsContainer {
    margin: 0 10px 0 30px;
    text-align: justify;
    padding-top: 5px;
  }
}

/* Octagon */
.octagonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}

.octagon1-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #000;
}

.octagon2-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #000;
}

.octagon3-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #000;
}

.octagon4-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #000;
}

@media screen and (max-width: 768px) {
  .octagon1 {
    text-align: center;
    width: 120px;
    height: 120px;
    position: relative;
    background-image: linear-gradient(to right bottom, #8BB3E4, #D5E4FA);
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  }

  .octagon2 {
    width: 120px;
    height: 120px;
    position: relative;
    background-image: linear-gradient(to right bottom, #D5E4FA 20%, #E0C2EB, #F8D5DF, #F695AD);
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  }

  .octagon3 {
    width: 120px;
    height: 120px;
    position: relative;
    background-image: linear-gradient(to right bottom, #F7B2C4 25%, #FCD9CE, #F7E186);
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  }

  .octagon4 {
    width: 120px;
    height: 120px;
    position: relative;
    background-image: linear-gradient(to right bottom, #F8DF98, #FDc37D, #FFC6B0);
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  }

  .octagon1-inner-content {
    font-size: medium;
  }

  .octagon1-img {
    display: block;
    margin: 10px auto auto auto;
    width: 40px;
    height: 40x;
  }

  .octagon2-inner-content {
    font-size: medium;
  }

  .octagon2-img {
    display: block;
    margin: 10px auto auto auto;
    width: 40px;
    height: 40x;
  }

  .octagon3-inner-content {
    font-size: medium;
  }

  .octagon3-img {
    display: block;
    margin: 10px auto auto auto;
    width: 40px;
    height: 40x;
  }

  .octagon4-inner-content {
    font-size: medium;
  }

  .octagon4-img {
    display: block;
    margin: 10px auto auto auto;
    width: 40px;
    height: 40x;
  }

  .octagon1Container {
    padding-top: 50px;
    display: flex;
    justify-content: center;
  }

  .octagon2Container {
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }

  .octagon3Container {
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }

  .octagon4Container {
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }

  .octagon1ContentContainer {
    padding-right: 20px;
    margin: auto 0;
    font-size: small;
  }

  .octagon2ContentContainer {
    padding-left: 20px;
    margin: auto 0;
    font-size: small;
  }

  .octagon3ContentContainer {
    padding-right: 20px;
    margin: auto 0;
    font-size: small;
  }

  .octagon4ContentContainer {
    padding-left: 20px;
    margin: auto 0;
    font-size: small;
  }

  .octagon1Content {
    padding-top: 10px;
    width: 160px;
  }

  .octagon2Content {
    padding-top: 10px;
    width: 160px;
  }

  .octagon3Content {
    padding-top: 10px;
    width: 160px;
  }

  .octagon4Content {
    padding-top: 10px;
    width: 160px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .octagon1 {
    text-align: center;
    width: 240px;
    height: 240px;
    position: relative;
    background-image: linear-gradient(to right bottom, #8BB3E4, #D5E4FA);
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  }

  .octagon2 {
    width: 240px;
    height: 240px;
    position: relative;
    background-image: linear-gradient(to right bottom, #D5E4FA 20%, #E0C2EB, #F8D5DF, #F695AD);
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  }

  .octagon3 {
    width: 240px;
    height: 240px;
    position: relative;
    background-image: linear-gradient(to right bottom, #F7B2C4 25%, #FCD9CE, #F7E186);
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  }

  .octagon4 {
    width: 240px;
    height: 240px;
    position: relative;
    background-image: linear-gradient(to right bottom, #F8DF98, #FDc37D, #FFC6B0);
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  }

  .octagon1-inner-content {
    font-size: xx-large;
  }

  .octagon1-img {
    display: block;
    margin: 10px auto auto auto;
    width: 80px;
    height: 80x;
  }

  .octagon2-inner-content {
    font-size: xx-large;
  }

  .octagon2-img {
    display: block;
    margin: 10px auto auto auto;
    width: 80px;
    height: 80x;
  }

  .octagon3-inner-content {
    font-size: xx-large;
  }

  .octagon3-img {
    display: block;
    margin: 10px auto auto auto;
    width: 80px;
    height: 80x;
  }

  .octagon4-inner-content {
    font-size: xx-large;
  }

  .octagon4-img {
    display: block;
    margin: 10px auto auto auto;
    width: 80px;
    height: 80x;
  }

  .octagon1Container {
    padding-top: 150px;
    display: flex;
    justify-content: center;
    margin-top: -50px;
    margin-right: 500px;
  }

  .octagon2Container {
    display: flex;
    justify-content: center;
    margin-top: -72px;
    margin-left: 500px;
  }

  .octagon3Container {
    display: flex;
    justify-content: center;
    margin-top: -72px;
    margin-right: 500px;
  }

  .octagon4Container {
    display: flex;
    justify-content: center;
    margin-top: -72px;
    margin-left: 500px;
  }

  .octagon1ContentContainer {
    padding-right: 20px;
    margin: auto 0;
  }

  .octagon2ContentContainer {
    padding-left: 20px;
    margin: auto 0;
  }

  .octagon3ContentContainer {
    padding-right: 20px;
    margin: auto 0;
  }

  .octagon4ContentContainer {
    padding-left: 20px;
    margin: auto 0;
  }

  .octagon1Content {
    padding-top: 10px;
    width: 310px;
  }

  .octagon2Content {
    padding-top: 10px;
    width: 310px;
  }

  .octagon3Content {
    padding-top: 10px;
    width: 310px;
  }

  .octagon4Content {
    padding-top: 10px;
    width: 310px;
  }
}

@media screen and (min-width: 1025px) {
  .octagon1 {
    text-align: center;
    width: 240px;
    height: 240px;
    position: relative;
    background-image: linear-gradient(to right bottom, #8BB3E4, #D5E4FA);
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  }

  .octagon2 {
    width: 240px;
    height: 240px;
    position: relative;
    background-image: linear-gradient(to right bottom, #D5E4FA 20%, #E0C2EB, #F8D5DF, #F695AD);
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  }

  .octagon3 {
    width: 240px;
    height: 240px;
    position: relative;
    background-image: linear-gradient(to right bottom, #F7B2C4 25%, #FCD9CE, #F7E186);
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  }

  .octagon4 {
    width: 240px;
    height: 240px;
    position: relative;
    background-image: linear-gradient(to right bottom, #F8DF98, #FDc37D, #FFC6B0);
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  }

  .octagon1-inner-content {
    font-size: xx-large;
  }

  .octagon1-img {
    display: block;
    margin: 10px auto auto auto;
    width: 80px;
    height: 80x;
  }

  .octagon2-inner-content {
    font-size: xx-large;
  }

  .octagon2-img {
    display: block;
    margin: 10px auto auto auto;
    width: 80px;
    height: 80x;
  }

  .octagon3-inner-content {
    font-size: xx-large;
  }

  .octagon3-img {
    display: block;
    margin: 10px auto auto auto;
    width: 80px;
    height: 80x;
  }

  .octagon4-inner-content {
    font-size: xx-large;
  }

  .octagon4-img {
    display: block;
    margin: 10px auto auto auto;
    width: 80px;
    height: 80x;
  }

  .octagon1Container {
    padding-top: 150px;
    display: flex;
    justify-content: center;
    margin-top: -50px;
    margin-right: 500px;
  }

  .octagon2Container {
    display: flex;
    justify-content: center;
    margin-top: -72px;
    margin-left: 500px;
  }

  .octagon3Container {
    display: flex;
    justify-content: center;
    margin-top: -72px;
    margin-right: 500px;
  }

  .octagon4Container {
    display: flex;
    justify-content: center;
    margin-top: -72px;
    margin-left: 500px;
  }

  .octagon1ContentContainer {
    padding-right: 20px;
    margin: auto 0;
  }

  .octagon2ContentContainer {
    padding-left: 20px;
    margin: auto 0;
  }

  .octagon3ContentContainer {
    padding-right: 20px;
    margin: auto 0;
  }

  .octagon4ContentContainer {
    padding-left: 20px;
    margin: auto 0;
  }

  .octagon1Content {
    padding-top: 10px;
    width: 310px;
  }

  .octagon2Content {
    padding-top: 10px;
    width: 310px;
  }

  .octagon3Content {
    padding-top: 10px;
    width: 310px;
  }

  .octagon4Content {
    padding-top: 10px;
    width: 310px;
  }
}

/* Breadcrumb */
.breadcrumb {
  display: flex;
  align-items: center;
  padding: 20px 0 20px 10px;
  margin: 0;
  list-style: none;
  color: #ffffff;
}

.breadcrumb li {
  margin-right: 10px;
}

.breadcrumb a {
  text-decoration: none;
  color: #ffffff;
}

.breadcrumb a:hover {
  text-decoration: underline;
}

/* stylishsection */
@media screen and (max-width: 768px) {
  .stylish-section-left {
    position: relative;
    height: 70vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .stylish-section-right {
    position: relative;
    height: 70vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .overlay-left {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 0.6) 40%);
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .overlay-right {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 0.6) 40%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .text-container {
    color: white;
    text-align: left;
    max-width: 500px;
    padding: 2rem;
  }
  
  .text-container h1 {
    font-size: 1.75em;
    margin-bottom: 2rem;
  }
  
  .text-container p {
    font-size: 1.25rem;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .stylish-section-left {
    position: relative;
    height: 90vh;
    background-size: cover;
    background-position: -20vw center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .stylish-section-right {
    position: relative;
    height: 90vh;
    background-size: cover;
    background-position: 20vw center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .overlay-left {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 1) 80%);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10%;
  }
  
  .overlay-right {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 1) 80%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10%;
  }

  .text-container {
    color: white;
    text-align: left;
    max-width: 550px;
    padding: 2rem;
  }
  
  .text-container h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .text-container p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 1025px) {
  .stylish-section-left {
    position: relative;
    height: 80vh;
    background-size: cover;
    background-position: -20vw center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .stylish-section-right {
    position: relative;
    height: 80vh;
    background-size: cover;
    background-position: 20vw center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .overlay-left {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 1) 80%);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20%;
  }
  
  .overlay-right {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 1) 80%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20%;
  }

  .text-container {
    color: white;
    text-align: left;
    max-width: 500px;
    padding: 2rem;
  }
  
  .text-container h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .text-container p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
}