* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* bold */
@font-face {
  font-family: "timeburnerbold";
  src: url("../src/fonts/timeburnerbold.ttf") format("truetype");
  src: url("../src/fonts/timeburnerbold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; 
}

body {
  font-family: '游明朝', 'Yu Mincho', 'Hiragino Mincho ProN', 'MS PMincho', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  display: flex;
  white-space: noWrap;
}

li {
  margin: 0 0 0 15px;
  font-size: 14px;
}

nav {
  margin: 0 0 0 auto;
}