/* FvSection */
@media screen and (max-width: 768px) {
  .FvSectionStyle {
    width: 100%;
    min-height: 100vh;
    background: url("/public/images/background-pic1.webp") center / cover;
    margin-top: -70px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .FvSectionStyle {
    width: 100%;
    min-height: 100vh;
    background: url("/public/images/background-pic1.webp") center / cover;
    margin-top: -80px;
  }
}

@media screen and (min-width: 1025px) {
  .FvSectionStyle {
    width: 100%;
    min-height: 100vh;
    background: url("/public/images/background-pic1.webp") center / cover;
    margin-top: -80px;
  }
}

.services-page {
  padding: 50px;
}

.services-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.services-group {
  width: calc(50% - 10px);
  margin-bottom: 20px;
}

.service-card {
  width: 100%;
  height: 800px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.service-card img {
  max-width: 100%;
  height: auto;
}

.service-card h3 {
  margin-top: 10px;
}

.service-card p {
  margin-top: 10px;
  font-size: large;
}

.email {
  font-size: 18px;
  text-align: center;
  color: #007bff;
}

.email:hover {
  text-decoration: underline;
}

/* CompanySection */
.CompanySectionStyle {
  /* background-color: #000B00; */
  padding: 80px 0 80px 0;
}

.CompanyTitleContainerStyle {
  display: flex;
  align-items: center;
  margin-left: 5%;
}

.CompanyTitleContainerStyle2 {
  display: flex;
  align-items: center;
  margin-left: 5%;
  padding-top: 40px;
}

.CompanyTitleContainerStyle3 {
  display: flex;
  align-items: center;
  margin-left: 5%;
  padding-top: 40px;
}

.CompanyContainerStyle dl {
  justify-content: center;
  align-items: center;
}

/* Companyコンポーネントのスタイル */
.Companay-Title {
  padding-left: 20px;
  left: 2%;
  top: 10%;
  font-size: 8rem;
  font-weight: 700;
}


.companyName {
  border-top: 1px solid #eaedf7;
}

@media screen and (max-width: 768px) {
  .CompanyTitleENStyle {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.05em;
  }

  .CompanyTitleJNStyle {
    margin: 0;
    font-size: 0.75em;
    color: #ffb0f3;
    letter-spacing: 0.05em;
  }

  .CompanyContainerStyle {
    padding-top: 40px;
    color: #ffffff;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }

  .CompanyContainerStyle dl {
    width: 100%;
  }

  .CompanyContainerStyle .row {
    display: flex;
    width: 100%;
    align-items: stretch;
  }

  .CompanyContainerStyle dt,
  .CompanyContainerStyle dd {
    padding: 10px;
    border-bottom: 1px solid #eaedf7;
    box-sizing: border-box;
  }

  .CompanyContainerStyle dt {
    flex-basis: 40%;
    font-weight: bold;
    padding-left: 10%;
    display: flex;
    align-items: center;
  }

  .CompanyContainerStyle dd {
    flex-basis: 70%;
    word-break: break-word;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .CompanyTitleENStyle {
    margin: 0;
    font-size: 3em;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.05em;
  }

  .CompanyTitleJNStyle {
    margin: 0;
    font-size: 1em;
    color: #ffb0f3;
    letter-spacing: 0.05em;
  }

  .CompanyContainerStyle {
    padding-top: 40px;
    color: #ffffff;
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
  }

  .CompanyContainerStyle dl {
    width: 100%;
  }

  .CompanyContainerStyle .row {
    display: flex;
    width: 100%;
    align-items: stretch;
  }

  .CompanyContainerStyle dt,
  .CompanyContainerStyle dd {
    padding: 20px;
    border-bottom: 1px solid #eaedf7;
    box-sizing: border-box;
  }

  .CompanyContainerStyle dt {
    flex-basis: 30%;
    font-weight: bold;
    padding-left: 10%;
    display: flex;
    align-items: center;
  }

  .CompanyContainerStyle dd {
    flex-basis: 70%;
    word-break: break-word;
  }
}

@media screen and (min-width: 1025px) {
  .CompanyTitleENStyle {
    margin: 0;
    font-size: 3em;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.05em;
  }

  .CompanyTitleJNStyle {
    margin: 0;
    font-size: 1em;
    color: #ffb0f3;
    letter-spacing: 0.05em;
  }

  .CompanyContainerStyle {
    padding-top: 40px;
    color: #ffffff;
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
  }

  .CompanyContainerStyle dl {
    width: 100%;
  }

  .CompanyContainerStyle .row {
    display: flex;
    width: 100%;
    align-items: stretch;
  }

  .CompanyContainerStyle dt,
  .CompanyContainerStyle dd {
    padding: 20px;
    border-bottom: 1px solid #eaedf7;
    box-sizing: border-box;
  }

  .CompanyContainerStyle dt {
    flex-basis: 30%;
    font-weight: bold;
    padding-left: 10%;
    display: flex;
    align-items: center;
  }

  .CompanyContainerStyle dd {
    flex-basis: 70%;
    word-break: break-word;
  }
}

/* Mvvコンポーネントのスタイル */

.Vision-Parent {
  padding: 5%;
  /* 余白指定 */
  background: linear-gradient(-225deg, #000000 0%, #3b3a40 56%, #737071 100%);
  width: 65vw;
  /* 幅指定 */
  position: relative;
  /* 位置指定の基準点にする */
  margin: 0 auto;
}

.Vision-Child {
  position: absolute;
  /* 位置指定 */
  top: 50%;
  /* 親要素の半分下にずらす */
  left: 50%;
  /* 親要素の半分右にずらす */
  transform: translateY(-50%) translateX(-50%);
  /* 要素自体の半分、上と左にずらす */
  padding: 20px;
  /* 余白指定 */
}

.Vision-Container {
  padding: 30px;
}

.Mvv-Title {
  color: rgb(255, 247, 247);
  font-size: 80px;
  text-align: center;
  padding-bottom: 40px;
}

.Mission-Title {
  color: red;
  font-size: 50px;
  border-bottom: solid 2px #ffffff;
  text-shadow: 1px 1px 1px #555, -1px 1px 1px #555, 1px -1px 1px #555, -1px -1px 1px #555, 1px 1px 1px #555, -1px 1px 1px #555, 1px -1px 1px #555, -1px -1px 1px #555, 5px 5px #ffffff;
  transform: rotate(2deg);
}

.Mission-Content {
  color: #fff;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  transform: rotate(2deg);
}

.Vision-Title {
  color: #0f009b;
  font-size: 50px;
  border-bottom: solid 2px #ffffff;
  text-shadow: 1px 1px 1px #555, -1px 1px 1px #555, 1px -1px 1px #555, -1px -1px 1px #555, 1px 1px 1px #555, -1px 1px 1px #555, 1px -1px 1px #555, -1px -1px 1px #555, 5px 5px #ffffff;
  transform: rotate(-2deg);
}

.Vision-Content {
  color: #fff;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  transform: rotate(-2deg);
}

.Value-Title {
  color: #5187c8;
  font-size: 50px;
  border-bottom: solid 2px #ffffff;
  text-shadow: 1px 1px 1px #555, -1px 1px 1px #555, 1px -1px 1px #555, -1px -1px 1px #555, 1px 1px 1px #555, -1px 1px 1px #555, 1px -1px 1px #555, -1px -1px 1px #555, 5px 5px #ffffff;
  transform: rotate(2deg);
}

.Value-Content {
  color: #fff;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  transform: rotate(2deg);
}

.mainContent {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  /* background-color: #f9f9f9;
  border-radius: 10px; */
  /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); */
}

/* ページタイトルスタイル */
.pageTitle {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

/* コンテンツボックススタイル */
.contentsBox p {
  text-align: center;
  margin-bottom: 30px;
  line-height: 1.6;
  color: #555;
}

/* フォーム入力フィールドのスタイル */
.formInput {
  width: calc(100% - 20px);
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  transition: border-color 0.3s ease;
}

.formInput:focus {
  border-color: #66afe9;
  outline: none;
}

/* ラベルスタイル */
label {
  display: block;
  margin: 10px 0 5px;
  font-weight: bold;
  color: #ffffff;
}

/* ボタンコンテナのスタイル */
.btns {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* ボタンのスタイル */
button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #0056b3;
}

button+button {
  margin-left: 10px;
  background-color: #6c757d;
}

button+button:hover {
  background-color: #5a6268;
}

/* 強調スタイル */
strong {
  font-weight: bold;
}

/* ServicesSection */
.ServicesSectionStyle {
  width: 100%;
  padding: 80px 0 0 0;
  position: relative;
  z-index: 2;
  /* background-color: #000B00; */
}

.ServicesTitleContainerStyle {
  margin-left: 5%;
}

@media screen and (max-width: 768px) {
  .ServicesTitleENStyle {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.05em;
  }

  .ServicesTitleJNStyle {
    margin: 0;
    font-size: 0.75em;
    color: #ffb0f3;
    letter-spacing: 0.05em;
  }

  .ServicesContainerStyle {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .ServicesTitleENStyle {
    margin: 0;
    font-size: 3em;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.05em;
  }

  .ServicesTitleJNStyle {
    margin: 0;
    font-size: 1em;
    color: #ffb0f3;
    letter-spacing: 0.05em;
  }

  .ServicesContainerStyle {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 1025px) {
  .ServicesTitleENStyle {
    margin: 0;
    font-size: 3em;
    color: #ffffff;
    letter-spacing: 0.05em;
  }

  .ServicesTitleJNStyle {
    margin: 0;
    font-size: 1em;
    color: #ffb0f3;
    letter-spacing: 0.05em;
  }

  .ServicesContainerStyle {
    padding-top: 40px;
  }
}

/* WorksSection */
.WorksSectionStyle {
  background-color: #ffffff;
  padding: 80px 0 80px 0;
}

.WorksTitleContainerStyle {
  display: flex;
  align-items: center;
  margin-left: 5%;
}

@media screen and (max-width: 768px) {
  .WorksTitleENBarStyle {
    width: 10px;
    height: 20px;
    background-color: #c22212;
    margin-right: 20px;
  }

  .WorksTitleENStyle {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
    color: black;
    letter-spacing: 0.05em;
  }

  .WorksContainerStyle {
    padding-top: 40px;
  }

  .WorksSymbolStyle {
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .WorksTitleENBarStyle {
    width: 10px;
    height: 30px;
    background-color: #c22212;
    margin-right: 20px;
  }

  .WorksTitleENStyle {
    margin: 0;
    font-size: 3em;
    font-weight: bold;
    color: black;
    letter-spacing: 0.05em;
  }

  .WorksContainerStyle {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    column-gap: 20px;
  }

  .WorksSymbolStyle {
    font-size: 30px;
    align-content: center;
  }
}

@media screen and (min-width: 1025px) {
  .WorksTitleENBarStyle {
    width: 10px;
    height: 30px;
    background-color: #c22212;
    margin-right: 20px;
  }

  .WorksTitleENStyle {
    margin: 0;
    font-size: 3em;
    font-weight: bold;
    color: black;
    letter-spacing: 0.05em;
  }

  .WorksContainerStyle {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    column-gap: 20px;
  }

  .WorksSymbolStyle {
    font-size: 30px;
    align-content: center;
  }
}

/* AllSection */
.AllSectionParticleContainer {
  position: relative;
}

/* AboutSection */
.AboutSectionStyle {
  width: 100%;
  padding: 80px 0 0 0;
  position: relative;
  z-index: 2;
}

.AboutTitleContainerStyle {
  margin-left: 5%;
}

.AboutSectionMoreButtonStyle:hover {
  background-color: white;
  color: black;
}

.AboutSectionArrow {
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .AboutTitleENStyle {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.05em;
  }

  .AboutTitleJNStyle {
    margin: 0;
    font-size: 0.75em;
    color: #ffb0f3;
    letter-spacing: 0.05em;
  }

  .AboutContainerStyle {
    padding-top: 40px;
    color: #ffffff;
    text-align: center;
    font-size: 1.1em;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .AboutContainerTitleStyle {
    font-size: larger;
  }

  .AboutContainerTitleContentsStyle {
    font-size: medium;
  }

  .AboutButtonContainerStyle {
    padding-top: 40px;
    display: flex;
    justify-content: center;
  }

  .AboutSectionMoreButtonStyle {
    background-color: transparent;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 10px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .AboutTitleENStyle {
    margin: 0;
    font-size: 3em;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.05em;
  }

  .AboutTitleJNStyle {
    margin: 0;
    font-size: 1em;
    color: #ffb0f3;
    letter-spacing: 0.05em;
  }

  .AboutContainerStyle {
    padding-top: 40px;
    color: #ffffff;
    text-align: center;
    font-size: 1.1em;
    margin-left: 15%;
    margin-right: 15%;
  }

  .AboutContainerTitleStyle {
    font-size: x-large;
  }

  .AboutContainerTitleContentsStyle {
    font-size: medium;
  }

  .AboutButtonContainerStyle {
    padding-top: 40px;
  }

  .AboutSectionMoreButtonStyle {
    background-color: transparent;
    color: white;
    padding: 5px 10px;
    font-size: 20px;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-left: 75%;
  }
}

@media screen and (min-width: 1025px) {
  .AboutTitleENStyle {
    margin: 0;
    font-size: 3em;
    color: #ffffff;
    letter-spacing: 0.05em;
  }

  .AboutTitleJNStyle {
    margin: 0;
    font-size: 1em;
    color: #ffb0f3;
    letter-spacing: 0.05em;
  }

  .AboutContainerStyle {
    padding-top: 40px;
    color: #ffffff;
    text-align: center;
    font-size: 1.1em;
    margin-left: auto;
    margin-right: auto;
  }

  .AboutContainerTitleStyle {
    font-size: xx-large;
  }

  .AboutContainerTitleContentsStyle {
    font-size: medium;
  }

  .AboutButtonContainerStyle {
    padding-top: 40px;
  }

  .AboutSectionMoreButtonStyle {
    background-color: transparent;
    color: white;
    padding: 5px 10px;
    font-size: 20px;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-left: 85%;
  }
}

/* AccessSection */
.AccessSectionStyle {
  padding: 80px 0 80px 0;
  background-color: #000000;
}

.AccessTitleEnStyle {
  text-align: center;
  font-size: 3em;
  font-weight: bold;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: 0.05em;
}

.AccessTitleJAStyle {
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  color: #c0c0c0;
  margin-bottom: 30px;
}

.AccessContainerStyle {
  text-align: center;
  color: #ffffff;
}

.AccessButtonContainetStyle {
  padding-top: 20px;
}

.AccessButtonStyle {
  display: block;
  margin: auto;
  width: 20%;
  height: 8vh;
  font-size: large;
}

/* ContactSection */

.ContactContainerStyle {
  padding-top: 10px;
  text-align: center;
  color: #ffffff;
  font-size: medium;
}

.ContactButtonContainer {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.ContactTopBorder {
  border-top: 2px solid white;
  padding-bottom: 30px;
  width: 80%;
  margin: -40px auto 0 auto;
}

.ContactBottomBorder {
  border-bottom: 2px solid white;
  padding-top: 30px;
  width: 80%;
  margin: 0 auto -40px auto;
}

.NonPopUp {
  display: none;
}

.PopUp {
  width: 50%;
  height: 30%;
  border: 1px solid #000;
  background-color: #fffafa;
  display: block;
  place-items: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.popupTitle {
  padding-top: 30px;
}

.popupContent {
  position: fixed;
  bottom: 30%;
  width: 100%;
  padding: 20px;
  text-align: center;
  font-size: larger;
}

.popupSelect {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px;
  text-align: center;
}



@media screen and (max-width: 768px) {

  .ContactSectionStyle {
    padding: 80px 10px 80px 10px;
    /* background-color: #000B00; */
  }

  .ContactTitle {
    text-align: center;
    font-size: large;
    font-weight: bold;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 0;
    letter-spacing: 0.05em;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {

  .ContactSectionStyle {
    padding: 80px 0 80px 0;
    /* background-color: #000B00; */
  }

  .ContactTitle {
    text-align: center;
    font-size: 3em;
    font-weight: bold;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 0;
    letter-spacing: 0.05em;
  }
}

@media screen and (min-width: 1025px) {

  .ContactSectionStyle {
    padding: 80px 0 80px 0;
  }

  .ContactTitle {
    text-align: center;
    font-size: 3em;
    font-weight: bold;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 0;
    letter-spacing: 0.05em;
  }
}

/* NewsSection */
.NewsSectionStyle {
  width: 100%;
  padding: 10px 0 0 0;
  position: relative;
  z-index: 2;
}

.NewsTitleContainerStyle {
  display: flex;
  align-items: center;
  margin-left: 5%;
}

.NewsContainerStyle {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NewsPostLinkStyle:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.NewsPostDateStyle {
  color: #5187c8;
  font-size: small;
  width: 5%;
}

.NewsPostTitleStyle {
  color: #ffffff;
  width: 70%;
}

.NewsSectionMoreButtonStyle:hover {
  background-color: white;
  color: black;
}

.NewsSectionArrow {
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .NewsPostStyle {
    border-bottom: 1px solid #ffffff;
    padding-bottom: 10px;
    align-items: center;
    gap: 100px;
    padding-top: 40px;
    width: 80vw;
  }

  .NewsPostRightStyle {
    display: flex;
    justify-content: space-between;
  }

  .NewsTitleENStyle {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.05em;
  }

  .NewsTitleJNStyle {
    margin: 0;
    font-size: 0.75em;
    color: #ffb0f3;
    letter-spacing: 0.05em;
  }

  .NewsButtonContainerStyle {
    padding-top: 40px;
    display: flex;
    justify-content: center;
  }

  .NewsSectionMoreButtonStyle {
    background-color: transparent;
    color: #ffffff;
    padding: 5px 10px;
    font-size: 20px;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .NewsPostStyle {
    display: flex;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 10px;
    align-items: center;
    gap: 100px;
    padding-top: 40px;
    width: 60vw;
  }

  .NewsPostRightStyle {
    display: flex;
    display: contents
  }

  .NewsTitleENStyle {
    margin: 0;
    font-size: 3em;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.05em;
  }

  .NewsTitleJNStyle {
    margin: 0;
    font-size: 1em;
    color: #ffb0f3;
    letter-spacing: 0.05em;
  }

  .NewsButtonContainerStyle {
    padding-top: 40px;
    display: flex;
    justify-content: center;
  }

  .NewsSectionMoreButtonStyle {
    background-color: transparent;
    color: #ffffff;
    padding: 5px 10px;
    font-size: 20px;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
}

@media screen and (min-width: 1025px) {
  .NewsPostStyle {
    display: flex;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 10px;
    align-items: center;
    gap: 100px;
    padding-top: 40px;
    width: 60vw;
  }

  .NewsPostRightStyle {
    display: flex;
    display: contents
  }

  .NewsTitleENStyle {
    margin: 0;
    font-size: 3em;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.05em;
  }

  .NewsTitleJNStyle {
    margin: 0;
    font-size: 1em;
    color: #ffb0f3;
    letter-spacing: 0.05em;
  }

  .NewsButtonContainerStyle {
    padding-top: 40px;
  }

  .NewsSectionMoreButtonStyle {
    background-color: transparent;
    color: #ffffff;
    padding: 5px 10px;
    font-size: 20px;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-left: 85%;
  }
}

/* NewsReleaseSingle */

.NewsReleaseSinglePostStyle {
  padding-bottom: 10px;
  align-items: center;
}

.NewsReleaseSinglePostDateStyle {
  width: 5%;
}

.NewsReleaseSinglePostTitleStyle {
  padding-top: 20px;
  font-size: xx-large;
}

.NewsReleaseSinglePostContentStyle {
  padding-top: 40px;
}

.NewsReleaseSingleButtonContainerStyle {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
}

.NewsReleaseSingleNewsReleaseSingle {
  background-color: transparent;
  border: 2px solid black;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.NewsReleaseSingleTitleContainerStyle {
  display: flex;
  align-items: center;
  margin-left: 5%;
  padding-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .NewsReleaseSingleContainerStyle {
    color: #ffffff;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .NewsReleaseSingleContainerStyle {
    color: #ffffff;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    width: 50vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1025px) {
  .NewsReleaseSingleContainerStyle {
    color: #ffffff;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    width: 50vw;
    margin-left: auto;
    margin-right: auto;
  }
}

/* CompanyPage */
.CompanyPageStyle {
  width: 100%;
  padding: 10px 0 0 0;
  position: relative;
  z-index: 2;
}

.ExcectiveName {
  border-top: 1px solid #ccc;
}

.CompanyMapContainerStyle {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .CompanyMapStyle {
    width: 80vw;
    height: 50vw;
    border: 1px solid #000;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .CompanyMapStyle {
    width: 80vw;
    height: 30vw;
    border: 1px solid #000;
  }
}

@media screen and (min-width: 1025px) {
  .CompanyMapStyle {
    width: 80vw;
    height: 30vw;
    border: 1px solid #000;
  }
}

/* ContactPage */

.ContactPageStyle {
  width: 100%;
  padding: 10px 0 0 0;
  position: relative;
  z-index: 2;
  color: #ffffff;
}

.ContactTitleContainerStyle {
  display: flex;
  align-items: center;
  margin-left: 5%;
}

@media screen and (max-width: 768px) {
  .ContactTitleENBarStyle {
    width: 10px;
    height: 20px;
    background-color: #c22212;
    margin-right: 20px;
  }

  .ContactTitleENStyle {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
    color: black;
    letter-spacing: 0.05em;
  }

  .ContactTopContent {
    display: none;
    margin-top: 20px;
  }

  .ContactTopContentResponsive {
    text-align: center;
    font-size: 1em;
    margin-top: 20px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .CompanyTitleENStyle {
    margin: 0;
    font-size: 3em;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.05em;
  }

  .CompanyTitleJNStyle {
    margin: 0;
    font-size: 1em;
    color: #ffb0f3;
    letter-spacing: 0.05em;
  }

  .ContactTopContent {
    text-align: center;
    font-size: 1.25em;
    margin-top: 20px;
  }

  .ContactTopContentResponsive {
    display: none;
    margin-top: 20px;
  }
}

@media screen and (min-width: 1025px) {
  .ContactTitleENBarStyle {
    width: 10px;
    height: 30px;
    background-color: #c22212;
    margin-right: 20px;
  }

  .ContactTitleENStyle {
    margin: 0;
    font-size: 3em;
    font-weight: bold;
    color: black;
    letter-spacing: 0.05em;
  }

  .ContactTopContent {
    text-align: center;
    font-size: 1.25em;
    margin-top: 20px;
  }

  .ContactTopContentResponsive {
    display: none;
    margin-top: 20px;
  }
}

/* ABOUT US Page */

.AboutPageStyle {
  width: 100%;
  padding: 10px 0 30px 0;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .AboutPageContent1 {
    font-size: 1.25em;
    font-weight: bold;
  }

  .AboutPageContainerStyle {
    margin-left: 5%;
  }

  .AboutContentsContainer {
    text-align: center;
    color: #ffffff;
    padding-top: 40px;
  }

  .AboutPageContent2 {
    padding-top: 40px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ffffff;
    display: inline-block;
    font-size: 1.25em;
  }

  .AboutPageContent3 {
    padding-top: 20px;
  }

  .AboutPageContent3-2 {
    padding-bottom: 20px;
  }

  .AboutPageContentEn4 {
    font-size: 1.5em;
    background-image: linear-gradient(90deg, rgba(168, 202, 240, 0.8), rgba(106, 158, 221, 0.8) 25%, rgba(222, 234, 248, 0.8) 63%, rgba(43, 130, 255, 0.8) 100%, rgba(192, 216, 243, 0.8));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }

  .AboutPageContentEn5 {
    font-size: 1.5em;
    background-image: linear-gradient(90deg, rgba(254, 220, 64, 0.8) 6%, rgba(252, 194, 80, 0.8) 22%, rgba(251, 173, 92, 0.8) 49%, rgba(255, 230, 165, 0.8) 64%, rgba(247, 93, 139, 0.8));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }

  .AboutPageContentEn6 {
    font-size: 1.5em;
    background-image: linear-gradient(90deg, rgba(141, 139, 226, 1), rgba(219, 215, 238, 1) 36%, rgba(230, 197, 232, 1) 53%, rgba(245, 225, 233, 1) 61%, rgba(253, 187, 203, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }

  .AboutPageContentEn7 {
    font-size: 1.5em;
    background-image: linear-gradient(90deg, rgba(251, 13, 84, 0.8), rgba(243, 123, 154, 0.8) 23%, rgba(255, 153, 176, 0.8) 36%, rgba(255, 243, 212, 0.8) 54%, rgba(234, 101, 139, 0.8) 100%, rgba(255, 171, 183, 0.8) 100%, rgba(254, 220, 64, 0.8));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }

  .AboutPageContentEn8 {
    font-size: 1.5em;
    background-image: linear-gradient(90deg, rgba(255, 242, 58, 1), rgba(244, 219, 158, 1) 31%, rgba(238, 208, 93, 1) 49%, rgba(252, 244, 195, 1) 64%, rgba(224, 162, 8, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .AboutPageContent1 {
    font-size: 1.5em;
    font-weight: bold;
  }

  .AboutPageContainerStyle {
    margin-left: 5%;
  }

  .AboutContentsContainer {
    text-align: center;
    color: #ffffff;
    padding-top: 40px;
  }

  .AboutPageContent2 {
    padding-top: 40px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ffffff;
    display: inline-block;
    font-size: 1.5em;
  }

  .AboutPageContent3 {
    padding-top: 20px;
  }

  .AboutPageContent3-2 {
    padding-bottom: 20px;
  }

  .AboutPageContentEn4 {
    font-size: 4em;
    background-image: linear-gradient(90deg, rgba(168, 202, 240, 0.8), rgba(106, 158, 221, 0.8) 25%, rgba(222, 234, 248, 0.8) 63%, rgba(43, 130, 255, 0.8) 100%, rgba(192, 216, 243, 0.8));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }

  .AboutPageContentEn5 {
    font-size: 4em;
    background-image: linear-gradient(90deg, rgba(254, 220, 64, 0.8) 6%, rgba(252, 194, 80, 0.8) 22%, rgba(251, 173, 92, 0.8) 49%, rgba(255, 230, 165, 0.8) 64%, rgba(247, 93, 139, 0.8));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }

  .AboutPageContentEn6 {
    font-size: 4em;
    background-image: linear-gradient(90deg, rgba(141, 139, 226, 1), rgba(219, 215, 238, 1) 36%, rgba(230, 197, 232, 1) 53%, rgba(245, 225, 233, 1) 61%, rgba(253, 187, 203, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }

  .AboutPageContentEn7 {
    font-size: 4em;
    background-image: linear-gradient(90deg, rgba(251, 13, 84, 0.8), rgba(243, 123, 154, 0.8) 23%, rgba(255, 153, 176, 0.8) 36%, rgba(255, 243, 212, 0.8) 54%, rgba(234, 101, 139, 0.8) 100%, rgba(255, 171, 183, 0.8) 100%, rgba(254, 220, 64, 0.8));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }

  .AboutPageContentEn8 {
    font-size: 4em;
    background-image: linear-gradient(90deg, rgba(255, 242, 58, 1), rgba(244, 219, 158, 1) 31%, rgba(238, 208, 93, 1) 49%, rgba(252, 244, 195, 1) 64%, rgba(224, 162, 8, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
}

@media screen and (min-width: 1025px) {
  .AboutPageContent1 {
    font-size: 1.5em;
    font-weight: bold;
  }

  .AboutPageContainerStyle {
    margin-left: 5%;
  }

  .AboutContentsContainer {
    text-align: center;
    color: #ffffff;
    padding-top: 40px;
  }

  .AboutPageContent2 {
    padding-top: 40px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ffffff;
    display: inline-block;
    font-size: 1.5em;
  }

  .AboutPageContent3 {
    padding-top: 20px;
  }

  .AboutPageContent3-2 {
    padding-bottom: 20px;
  }

  .AboutPageContentEn4 {
    font-size: 4em;
    background-image: linear-gradient(90deg, rgba(168, 202, 240, 0.8), rgba(106, 158, 221, 0.8) 25%, rgba(222, 234, 248, 0.8) 63%, rgba(43, 130, 255, 0.8) 100%, rgba(192, 216, 243, 0.8));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }

  .AboutPageContentEn5 {
    font-size: 4em;
    background-image: linear-gradient(90deg, rgba(254, 220, 64, 0.8) 6%, rgba(252, 194, 80, 0.8) 22%, rgba(251, 173, 92, 0.8) 49%, rgba(255, 230, 165, 0.8) 64%, rgba(247, 93, 139, 0.8));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }

  .AboutPageContentEn6 {
    font-size: 4em;
    background-image: linear-gradient(90deg, rgba(141, 139, 226, 1), rgba(219, 215, 238, 1) 36%, rgba(230, 197, 232, 1) 53%, rgba(245, 225, 233, 1) 61%, rgba(253, 187, 203, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }

  .AboutPageContentEn7 {
    font-size: 4em;
    background-image: linear-gradient(90deg, rgba(251, 13, 84, 0.8), rgba(243, 123, 154, 0.8) 23%, rgba(255, 153, 176, 0.8) 36%, rgba(255, 243, 212, 0.8) 54%, rgba(234, 101, 139, 0.8) 100%, rgba(255, 171, 183, 0.8) 100%, rgba(254, 220, 64, 0.8));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }

  .AboutPageContentEn8 {
    font-size: 4em;
    background-image: linear-gradient(90deg, rgba(255, 242, 58, 1), rgba(244, 219, 158, 1) 31%, rgba(238, 208, 93, 1) 49%, rgba(252, 244, 195, 1) 64%, rgba(224, 162, 8, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
}

/* ServicePageStyle */

.ServicePageContainerStyle {
  display: flex;
  align-items: center;
  margin-left: 5%;
}

.ServicePageFlowTitle {
  padding-top: 80px;
  width: 80%;
  margin: 0 auto;
}

.ServicePageFlowTitle h2:before,
.ServicePageFlowTitle h2:after {
  position: absolute;
  content: '';
}

.ServicePageFlowTitle h2:before {
  top: -40px;
  left: calc(50% - 40px);
  width: 80px;
  height: 80px;
  border: 2px solid #000;
  border-radius: 50%;
  background: #3e97e3;
}

.ServicePageFlowTitle h2:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #3e97e3;
}

.ServicePageFlowTitle h2 i {
  line-height: 60px;
  position: absolute;
  z-index: 1;
  top: -40px;
  left: calc(50% - 40px);
  width: 80px;
  height: 60px;
  text-align: center;
}

.ServicePageFlowTitle h2 span {
  position: relative;
  z-index: 1;
}

.YoutubeIntroduceContainer {
  padding-left: 20px;
  color: #ffffff;
}

.YoutubeTopContainer {
  margin: auto 0;
}

.YoutubeIntroduceBottom {
  padding-top: 30px;
  display: block;
  padding-left: 20px;
  padding-right: 20px;
}


@media screen and (max-width: 768px) {
  .ServicePageSectionStyle {
    width: 100%;
    padding: 10px 0 0 0;
    position: relative;
    z-index: 2;
  }

  .ServicePageCardConainer {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    padding-top: 40px;
    color: #ffffff;
  }

  .ServicePageENStyle {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.05em;
  }

  .ServicePageJNStyle {
    margin: 0;
    font-size: 0.75em;
    color: #ffb0f3;
    letter-spacing: 0.05em;
  }


  .ServicePageFlowTitle h2 {
    font-size: 1.5em;
    text-align: center;
    position: relative;
    padding: 1rem;
    text-align: center;
    border: 2px solid #000;
    background: #fff;
  }

  .ServicePageH3Title1 {
    color: #f8eb35;
    font-size: 2em;
    padding-top: 40px;
    text-align: center;
    text-shadow:
      0 1px #acacac,
      1px 2px #acacac,
      2px 1px 8px #acacac;
  }

  .ServicePageH3Title2 {
    color: #acdffe;
    font-size: 2em;
    padding-top: 40px;
    text-align: center;
    text-shadow:
      0 1px #acacac,
      1px 2px #acacac,
      2px 1px 8px #acacac;
  }

  .ServicePageH3Title3 {
    color: #f492a2;
    font-size: 2em;
    padding-top: 40px;
    text-align: center;
    text-shadow:
      0 1px #acacac,
      1px 2px #acacac,
      2px 1px 8px #acacac;
  }

  .YoutubeWrapper {
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: center;
    display: block;
    margin: auto;
  }

  .YoutubeContainer {
    padding: 20px;
    background-color: #333;
  }

  .Youtube {
    width: 300px;
    height: 200px;
    border: transparent;
    display: block;
    margin: auto;
  }

  .YoutubeCircle {
    background-image: url('./images/profile_yuuro.webp');
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    width: 70px;
    height: 70px;
  }

  .YoutubeIntroduceTop {
    padding-top: 20px;
    display: flex;
  }

  .YoutubeName {
    font-size: larger;
    padding-left: 20px;
    margin: auto 0;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .ServicePageSectionStyle {
    width: 100%;
    padding: 10px 0 0 0;
    position: relative;
    z-index: 2;
  }

  .ServicePageCardConainer {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    padding-top: 40px;
    color: #ffffff;
  }

  .ServicePageENStyle {
    margin: 0;
    font-size: 3em;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.05em;
  }

  .ServicePageJNStyle {
    margin: 0;
    font-size: 1em;
    color: #ffb0f3;
    letter-spacing: 0.05em;
  }

  .ServicePageFlowTitle h2 {
    font-size: 3em;
    text-align: center;
    position: relative;
    padding: 1.5rem;
    text-align: center;
    border: 2px solid #000;
    background: #fff;
  }

  .ServicePageH3Title1 {
    color: #f8eb35;
    font-size: 2em;
    padding-top: 40px;
    margin-left: 20%;
    text-shadow:
      0 1px #acacac,
      1px 2px #acacac,
      2px 1px 8px #acacac;
  }

  .ServicePageH3Title2 {
    color: #acdffe;
    font-size: 2em;
    padding-top: 40px;
    margin-left: 20%;
    text-shadow:
      0 1px #acacac,
      1px 2px #acacac,
      2px 1px 8px #acacac;
  }

  .ServicePageH3Title3 {
    color: #f492a2;
    font-size: 2em;
    padding-top: 40px;
    margin-left: 20%;
    text-shadow:
      0 1px #acacac,
      1px 2px #acacac,
      2px 1px 8px #acacac;
  }

  .YoutubeWrapper {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
  }

  .YoutubeContainer {
    padding: 20px;
    background-color: #333;
    display: flex;
  }

  .Youtube {
    width: 500px;
    height: 300px;
    border: transparent;
  }

  .YoutubeCircle {
    background-image: url('./images/profile_yuuro.webp');
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }

  .YoutubeIntroduceTop {
    display: flex;
  }

  .YoutubeName {
    font-size: x-large;
    padding-left: 20px;
    margin: auto 0;
  }
}

@media screen and (min-width: 1025px) {
  .ServicePageSectionStyle {
    width: 100%;
    padding: 10px 0 0 0;
    position: relative;
    z-index: 2;
  }

  .ServicePageCardConainer {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    padding-top: 40px;
    color: #ffffff;
  }

  .ServicePageENStyle {
    margin: 0;
    font-size: 3em;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.05em;
  }

  .ServicePageJNStyle {
    margin: 0;
    font-size: 1em;
    color: #ffb0f3;
    letter-spacing: 0.05em;
  }

  .ServicePageFlowTitle h2 {
    font-size: 3em;
    text-align: center;
    position: relative;
    padding: 1.5rem;
    text-align: center;
    border: 2px solid #000;
    background: #fff;
  }

  .ServicePageH3Title1 {
    color: #f8eb35;
    font-size: 2em;
    padding-top: 40px;
    margin-left: 20%;
    text-shadow:
      0 1px #acacac,
      1px 2px #acacac,
      2px 1px 8px #acacac;
  }

  .ServicePageH3Title2 {
    color: #acdffe;
    font-size: 2em;
    padding-top: 40px;
    margin-left: 20%;
    text-shadow:
      0 1px #acacac,
      1px 2px #acacac,
      2px 1px 8px #acacac;
  }

  .ServicePageH3Title3 {
    color: #f492a2;
    font-size: 2em;
    padding-top: 40px;
    margin-left: 20%;
    text-shadow:
      0 1px #acacac,
      1px 2px #acacac,
      2px 1px 8px #acacac;
  }

  .YoutubeWrapper {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    max-width: 70%;
    margin: auto;
  }

  .YoutubeContainer {
    padding: 20px;
    background-color: #333;
    display: flex;
  }

  .Youtube {
    width: 500px;
    height: 300px;
    border: transparent;
  }

  .YoutubeCircle {
    background-image: url('./images/profile_yuuro.webp');
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }

  .YoutubeIntroduceTop {
    display: flex;
  }

  .YoutubeName {
    font-size: x-large;
    padding-left: 20px;
    margin: auto 0;
  }
}